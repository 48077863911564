import React from 'react';
import { Button, ButtonSize, ButtonVariant } from '@naf/button';
import { useGTMDataLayer } from '../../hooks/useGTMDataLayer';

interface CtaButtonProps {
  text: string;
  variant: ButtonVariant;
  size?: ButtonSize;
  onClickFn?: () => void;
  submit?: boolean;
  isLoading?: boolean;
}

const CtaButton = ({ text, variant, size, onClickFn, submit = false, isLoading = false }: CtaButtonProps) => {
  const dataLayer = useGTMDataLayer();

  const handleTracking = () => {
    dataLayer?.push({
      event: 'cta_click',
      cta_text: text,
      cta_location: window.location.pathname,
      cta_url: window.location.href,
      cta_hostname: window.location.hostname,
    });
  };

  return (
    <Button
      type={submit ? 'submit' : 'button'}
      size={size ?? 'medium'}
      variant={variant}
      onClick={() => {
        handleTracking();
        if (onClickFn) onClickFn();
      }}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
};

export default CtaButton;
